import React from "react"
import PostItems from "./PostItems"
import PostItem from "./PostItem"

const LatestPosts = ({ title, introduction }) => {
  return (
    <div className="section">
      <div className="container container__tight">
        {title || introduction ? (
          <div className="intro__area">
            {title && (
              <h2>
                {title}
                <span>.</span>
              </h2>
            )}
            {introduction && <p>{introduction}</p>}
          </div>
        ) : null}

        <PostItems>
          {[{
            title: 'Hassle-Free Service',
            introduction: 'With our comprehensive service, you can focus on what you do best - running your business - while we take care of your online presence. We are committed to providing a seamless, hassle-free experience, ensuring your website is always running smoothly and effectively.'
          }, {
            title: 'Our Journey',
            introduction: 'Founded on the belief that every business, big or small, deserves a powerful online presence, Dynamic Web Partners has been the driving force behind numerous success stories. Our team of seasoned engineers and SEO experts consistently delivers results that exceed expectations.'
          }, {
            title: 'Get Started Today',
            introduction: 'Find out how Dynamic Web Partners can empower your business to thrive in the digital world. Whether you\'re looking for a brand-new website, ongoing maintenance, or a temporary web solution, we\'re here to make the process seamless and stress-free.'
          }].map((node, index) => {
            return <PostItem key={index} node={node} />
          })}
        </PostItems>
      </div>
    </div>
  )
}

export default LatestPosts
