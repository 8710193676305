import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import LatestPosts from "../components/Post/LatestPosts"
import LatestPostsTwo from "../components/Post/LatestPostsTwo"
import LatestPostsThree from "../components/Post/LatestPostsThree"

const Index = () => {
    return (
        <>
            <Seo title="Home"/>
            <Layout>
                <BannerModule
                    title="Empowering Your Digital Journey"
                    subTitle="We’re at the forefront of SEO optimization, ensuring your business reaches its highest online potential."
                />
                <BasicTextModule
                    title="In the digital age, having a strong online presence is no longer an option, but a necessity."
                    content="That's where we come in. Welcome to Dynamic Web Partners, your one-stop solution for all your website needs. From hosting to optimized search engine optimization, we've got you covered."
                    link="/products"
                    linkText=""
                />
                {/*<PerksModule>*/}
                {/*    <Perk title="The Title" content="The content"/>*/}
                {/*</PerksModule>*/}
                {/*<Features*/}
                {/*    title="Featured Products from Barcadia."*/}
                {/*    introduction="Vivamus quam mauris, pulvinar vel mauris id, interdum semper neque. Proin malesuada libero eget tellus scelerisque, id egestas tortor egestas."*/}
                {/*/>*/}
                <LatestPosts
                    title="Not tech-savvy? No problem"
                    introduction="We provide a range of services to ensure your website runs smoothly. From setting up your site, ongoing maintenance, to handling technical issues, our team is always on hand to provide support and keep your online operations seamless and worry-free."
                />
                <LatestPostsTwo
                    title="Step into the future with cutting-edge SEO strategies tailored for your business."
                    introduction="At Dynamic Web Partners, we understand the intricacies of the digital world. Our expertise isn't just about ranking higher on search engines—it's about connecting your brand with the right audience, driving organic growth, and converting clicks into loyal customers. Let's co-create your success story."
                />
                <LatestPostsThree
                    title="Have Questions?"
                    introduction="We appreciate your interest in Dynamic Web Partners, and we look forward to serving you with our optimized service. Your satisfaction is our top priority, and we are committed to delivering an exceptional experience. Reach out to us today, and let us help you unlock the full potential of rentals for your needs."
                />
            </Layout>
        </>
    )
}

export default Index
