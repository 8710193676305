import * as React from "react"
import {BasicTextModuleStyles} from "./BasicTextModuleStyles"

const BasicTextModule = ({title, content}) => {
    return (
        <>
            <div id="content-section"/>
            <BasicTextModuleStyles className="section">
                <div className="container container__tight">
                    <div>
                        {title && <h2>{title}</h2>}
                        {content && <p style={{marginBottom: "60px"}}>{content}</p>}
                    </div>
                </div>
            </BasicTextModuleStyles>
        </>
    )
}

export default BasicTextModule
