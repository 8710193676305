import * as React from "react"
import {BannerModuleStyles} from "./BannerModuleStyles"
import {StaticImage} from "gatsby-plugin-image"
import Button from "../Button/Button"

const BannerModule = ({children, title, subTitle, price, enquire}) => {
    const handleClickScroll = () => {
        console.log('clicked')
        const element = document.getElementById('content-section');
        console.log(element, "elemntn?")
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <>
            <BannerModuleStyles>
                {children ? (
                    children
                ) : (
                    <StaticImage
                        className="banner__image"
                        imgClassName="banner__image--content"
                        src="../../../static/macbook-color.jpg"
                        alt="Banner Image"
                        layout="fullWidth"
                        placeholder="blurred"
                    />
                )}

                <div className="container">
                    <div className="banner__content">
                        {title && (
                            <h1>
                                {title}
                                <span style={{color: "var(--primary)"}}>.</span>
                            </h1>
                        )}
                        {subTitle && <h2>{subTitle}</h2>}
                        {price && (
                            <h2 className="price">
                                £{price}
                                <span style={{color: "var(--primary)"}}>.</span>
                            </h2>
                        )}
                        <div className="banner__btns">
                            <Button onClick={handleClickScroll} text="Learn More" arrow={true}/>
                        </div>
                    </div>
                </div>
                <div className="gradient"></div>
            </BannerModuleStyles>
            <span id="topContent"></span>
        </>
    )
}

export default BannerModule
