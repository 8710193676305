import React from "react"
// import Button from "../Button/Button"
// import {Link} from "gatsby";

const LatestPostsTwo = ({ title, introduction }) => {
  return (
    <div className="section">
      <div className="container container__tight">
        {title || introduction ? (
          <div className="intro__area">
            {title && (
              <h2>
                {title}
              </h2>
            )}
            {introduction && <p>{introduction}</p>}
          </div>
        ) : null}

        {/*<PostItems>*/}
        {/*  {[{*/}
        {/*    title: 'Hassle-Free Service',*/}
        {/*    introduction: 'With our comprehensive service, you can focus on what you do best - running your business - while we take care of your online presence. We are committed to providing a seamless, hassle-free experience, ensuring your website is always running smoothly and effectively.'*/}
        {/*  }, {*/}
        {/*    title: 'Website Rentals',*/}
        {/*    introduction: 'Our website rental service is the perfect solution. No long-term commitment, no hassle - just a swift, professional online presence whenever you need it. Our team of experts handles everything from setting up your site, maintaining its performance, to troubleshooting any technical issues. '*/}
        {/*  }, {*/}
        {/*    title: 'Get Started Today',*/}
        {/*    introduction: 'Find out how Dynamic Web Partners can empower your business to thrive in the digital world. Whether you\'re looking for a brand-new website, ongoing maintenance, or a temporary web solution, we\'re here to make the process seamless and stress-free.'*/}
        {/*  }].map((node, index) => {*/}
        {/*    return <PostItem key={index} node={node} />*/}
        {/*  })}*/}
        {/*</PostItems>*/}
        {/*<div className="learn__more">*/}
        {/*  <Button text="Get Started Now" as={Link} to="https://buy.stripe.com/00g4jF6EJc61bWU5kw" />*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default LatestPostsTwo
